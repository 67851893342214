// USA
export const locale = {
  TRANSLATOR: {
    SELECT: "Select your language",
  },
  MENU: {
    NEW: "new",
    ACTIONS: "Actions",
    CREATE_POST: "Create New Post",
    PAGES: "Pages",
    FEATURES: "Features",
    APPS: "Apps",
    DASHBOARD: "Dashboard",
  },
  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact",
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In",
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset.",
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered.",
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username",
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid",
      // "Invalid Email or Password": "Invalid Email or Password",
      token_expired: "Token expired, You are logged out",
    },
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields",
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted",
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted",
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated",
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created",
      },
    },
  },
  PERMISSIONS: {
    module_system_menu: "System settings modul",
    module_companySettings_menu: "Company setting modul",

    system_setting_menu: "System settings - System settings menu",
    system_setting_update: "System settings - Update system settings",
    system_setting_view: "System settings - View system settings",
    system_user_menu: "System settings - Users menu",
    system_user_create: "System settings - Create user",
    system_user_delete: "System settings - Delete user",
    system_user_update: "System settings - Update user",
    system_user_update_self: "System settings - Update user self",
    system_user_view: "System settings - View user",
    system_user_view_self: "System settings - View user self",
    system_userGroup_menu: "System settings - User groups menu",
    system_userGroup_create: "System settings - Create user group",
    system_userGroup_delete: "System settings - Delete user group",
    system_userGroup_update: "System settings - Update user group",
    system_userGroup_update_self: "System settings - Update user group self",
    system_userGroup_view: "System settings - View user group",
    system_userGroup_view_self: "System settings - View user group self",
    system_role_menu: "System settings - Roles menu",
    system_role_create: "System settings - Create role",
    system_role_delete: "System settings - Delete role",
    system_role_update: "System settings - Update role",
    system_role_view: "System settings - View role",
    system_country_menu: "System settings - Countries menu",
    system_country_update: "System settings - Update country",
    system_country_view: "System settings - View country",
    system_currency_menu: "System settings - Currencies menu",
    system_currency_create: "System settings - Create currency ",
    system_currency_delete: "System settings - Delete currency",
    system_currency_update: "System settings - Update currency",
    system_currency_view: "System settings - View currency",

    companySettings_department_menu: "Company settings - Departments menu",
    companySettings_department_create: "Company settings - Create department",
    companySettings_department_delete: "Company settings - Delete department",
    companySettings_department_update: "Company settings - Update department",
    companySettings_department_view: "Company settings - View department",
    companySettings_site_menu: "Company settings - Sites menu",
    companySettings_site_create: "Company settings - Create site",
    companySettings_site_delete: "Company settings - Delete site",
    companySettings_site_update: "Company settings - Update site",
    companySettings_site_view: "Company settings - View site",

    system: "System settings",
    companySettings: "Company settings",

    module: "Modules",
  },

  SALESORDERSSTATUSES: {
    new: "New",
    waiting_for_offer: "Waiting for offer",
    offer_approval: "Offer approval",
    offer_done: "Offer done",
    order_approval: "Order approval",
    ordered: "Ordered",
    contracted: "Contracted",
    to_be_produced: "To be produced",
    in_production: "In production",
    to_be_delivered: "To be delivered",
    delivered: "Delivered",
    closed: "Closed",
    revoked: "Revoked",
  },

  FORM_INPUT_NAMES: {
    total_net: "Total net",
    total_gross: "Total gross",
    owner_id: "Owner",
    paid: "Paid",
    lost: "Lost",
    source_type_id: "Source type",
    locale: "Locale",
    customer: "Customer",
    billingAddress: "Billing address",
    shippingAddress: "Shipping address",
    expiration_date: "Expiration date",
    delivery_date: "Delivery date",
    delivery_days: "Delivery days",
    price_list_id: "Priece list",
    discount_value: "Discount value",
    discountNetValue: "Discount net value",
    notes: "Comment",
    status: "Status",
  },
  EMAIL: {
    offer: "Árajánlat romanul",
  },
};
