import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service.js";
import SiteService from "@/core/services/site.service.js";

const state = {
  errors: null,
  collection: [],
};

const getters = {
  blogCategoryCollection(state) {
    return state.collection;
  },

  getBlogCategoryByID: (state) => (id) => {
    let item = state.collection.find((item) => item.id == id);
    if (!item) {
      item = { name: "" };
    }
    return item;
  },
};

const actions = {
  fetchBlogCategory(context) {
    return new Promise((resolve) => {
      let appLocale = i18nService.getUserActiveLanguage();
      ApiService.get(
        "blog/admin/" + SiteService.getActiveSiteId() + "/category"
      ).then(({ data }) => {
        // create localized fields
        let dataLocalized = data.map((item) => {
          if (item.translations) {
            let translations = item.translations[appLocale];
            if (translations) {
              Object.keys(translations).forEach((property) => {
                item[property] = item.translations[appLocale][property];
              });
              item.translated = true;
            } else {
              for (const [key, value] of Object.entries(
                item.translations[Object.keys(item.translations)[0]]
              )) {
                if ("attachments" != key) {
                  item[key] = value;
                }
              }
            }
          }

          return item;
        });

        context.commit("fetchBlogCategory", dataLocalized);
        resolve();
      });
    }).catch((error) => {
      console.log("Error!: ", error);
    });
  },
};

const mutations = {
  fetchBlogCategory(state, data) {
    state.collection = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
