import ApiService from "@/core/services/api.service";

export const FETCH_ROLE = "fetchRole";
export const ROLE_COLLECTION = "roleCollection";

const state = {
    errors: null,
    collection: [],
};

const getters = {
    [ROLE_COLLECTION](state) {
        return state.collection;
    }
};

const actions = {
    [FETCH_ROLE](context) {
        ApiService.get("role")
            .then(({ data }) => {
                context.commit(FETCH_ROLE, data);
            })
            .catch((error) => {
                console.log('Error!: ', error);
            });
    },
}

const mutations = {
    [FETCH_ROLE](state, data) {
        state.collection = data;
    },

    // createRole(state, data) {
    //     state.collection.push(data)
    // },
};

export default {
    state,
    actions,
    mutations,
    getters
};
