import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service.js";
import SiteService from "@/core/services/site.service.js";

const state = {
  errors: null,
  collection: [],
};

const getters = {
  menuCollection(state) {
    return state.collection;
  },

  getMenuByID: (state) => (id) => {
    return state.collection.find((item) => item.id == id);
  },
};

const actions = {
  fetchMenu(context) {
    return new Promise((resolve) => {
        let appLocale = i18nService.getActiveLanguage();
        ApiService.get("pages/admin/" + SiteService.getActiveSiteId() + "/menu")
              .then(({ data }) => {
                // create localized fields
                let dataLocalized = data.map((item) => {
                  if (item.translations.length == 0) {
                    item.name = "Name not defined";
                    return item;
                  }
                  if (item.translations[appLocale]) {
                    item.name = item.translations[appLocale].name;
                    item.label = item.translations[appLocale].label;
                  } else {
                    item.name =
                      item.translations[Object.keys(item.translations)[0]].name;
                    item.label =
                      item.translations[Object.keys(item.translations)[0]].label;
                  }

                  return item;
                });
                
                context.commit("fetchMenu", dataLocalized);
                
                resolve();
              })
              .catch((error) => {
                console.log("Error!: ", error);
              });
    });
  },
};

const mutations = {
  fetchMenu(state, data) {
    state.collection = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
